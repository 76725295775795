import request, { CommonListResponse, IListWorkspaceResponse, IPage, IWorkspaceResponse } from '/@/api/http/request'

const HTTP_PREFIX = '/terra/api/v1'

// login
export interface LoginBody {
 username: string,
 password: string,
 flag: number,
}
export interface BindBody {
  device_sn: string,
  user_id: string,
  workspace_id: string,
  domain?: string
}
export interface HmsQueryBody {
  sns: string[],
  children_sn: string,
  device_sn: string,
  language: string,
  level: number | string,
  begin_time: number,
  end_time: number,
  message: string,
  domain: number,
}

// Get job list
export const getJobList = async function (workspace_id: string): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/${workspace_id}/job/list`
  const result = await request.get(url)
  return result.data
}

// create Job
export const createJob = async function (workspace_id: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/${workspace_id}/job/create`
  const result = await request.post(url, body)
  return result.data
}

// update Job
export const updateJob = async function (workspace_id: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/${workspace_id}/job/update`
  const result = await request.post(url, body)
  return result.data
}

// down Job
export const downJob = async function (workspace_id: string, jobId: string): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/${workspace_id}/job/down?jobId=${jobId}`
  const result = await request.get(url, { responseType: 'blob' })
  return result.data
}

// upload Job
export const uploadJob = async function (workspace_id: string, body: FormData): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/${workspace_id}/job/upload`
  const result = await request.post(url, body)
  return result.data
}
