import 'ant-design-vue/dist/reset.css'
import Antd from 'ant-design-vue'
import { App } from 'vue'
import svgIcon from '/@/components/svgIcon.vue'

export const antComponents = {
  install (app: App): void {
    app.use(Antd)
    app.component('svg-icon', svgIcon)
  }
}
