import { EFlightAreaType } from '../types/flight-area'
import { log } from 'console'
import { WaylinePoint } from '../types/wayline'
import pin19be6b from '/@/assets/icons/pin-19be6b.svg'
import pin212121 from '/@/assets/icons/pin-212121.svg'
import pin2d8cf0 from '/@/assets/icons/pin-2d8cf0.svg'
import pinb620e0 from '/@/assets/icons/pin-b620e0.svg'
import pine23c39 from '/@/assets/icons/pin-e23c39.svg'
import pineffbb00 from '/@/assets/icons/pin-ffbb00.svg'
import point from '/@/assets/icons/pointblue.svg'
import rectangle from '/@/assets/icons/rectangle.svg'
import { getRoot } from '/@/root'
import rootStore from '/@/store'
import { GeojsonCoordinate } from '/@/types/map'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'

export function useGMapCover () {
  const root = getRoot()
  const AMap = root.$aMap
  const Cesium = root.$Cesium
  const viewer = root.$viewer

  const normalColor = '#2D8CF0'
  const greenColor = '#00FA9A'
  const blueColor = '#037aff'
  const yelloColor = '#FFFF00'
  const store = rootStore
  const coverList = store.state.coverList
  const coverMap = store.state.coverMap
  const coverFolderMap = store.state.coverFolderMap
  const flightAreaColorMap = {
    [EFlightAreaType.DFENCE]: '#19be6b',
    [EFlightAreaType.NFZ]: '#ff0000',
  }
  const disableColor = '#b3b3b3'
  const waylineList = store.state.waylineList

  function AddCoverToMap (layerId: string, cover :any) {
    coverMap[layerId] = [cover]
  }

  function getPinIcon (color?:string) {
    const colorObj: {
      [key: number| string]: any
    } = {
      '2d8cf0': pin2d8cf0,
      '19be6b': pin19be6b,
      212121: pin212121,
      b620e0: pinb620e0,
      e23c39: pine23c39,
      ffbb00: pineffbb00,
      point: point,
      rectangle: rectangle
    }
    const iconName = (color?.replaceAll('#', '') || '').toLocaleLowerCase()
    return colorObj[iconName]
    // return new AMap.Icon({
    //   // size: new AMap.Size(40, 50),
    //   image: colorObj[iconName],
    //   // imageOffset: new AMap.Pixel(0, -60),
    //   // imageSize: new AMap.Size(40, 50)
    // })
  }

  function init2DPin (layerId: string, name: string, coordinates:GeojsonCoordinate, color?:string, data?:{}) {
    // console.log(name, coordinates[0], coordinates[1], color, data)
    const icon = getPinIcon(color) || color
    // console.log(icon, 'icon')
    const point = viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1]),
      billboard: {
        id: layerId,
        eyeOffset: Cesium.Cartesian3(0.0, 0.0, 0.0),
        image: icon, // default: undefined
        show: true, // default
        alignedAxis: Cesium.Cartesian3.clone(Cesium.Cartesian3.UNIT_Z),
        width: 40, // default: undefined
        height: 40, // default: undefined
      },
      label: {
        text: name,
        font: '18px sans-serif',
        fillColor: Cesium.Color.BLACK,
        outlineColor: Cesium.Color.WHITE,
        outlineWidth: 2,
        style: Cesium.LabelStyle.FILL_AND_OUTLINE,
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
        pixelOffset: new Cesium.Cartesian2(32, 8),
      },
    })
    AddCoverToMap(layerId, point)
  }

  function init2DPinFolder (layerId: string, name: string, coordinates:GeojsonCoordinate, color?:string, data?:{}) {
    // console.log('init2DPinFolder===', name, coordinates, color, data)
    const icon = getPinIcon(color) || color
    // console.log(icon, 'icon')
    const point = root.$viewer.entities.add({
      position: root.$Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1], coordinates[2]),
      billboard: {
        id: layerId,
        eyeOffset: root.$Cesium.Cartesian3(0.0, 0.0, 0.0),
        image: icon, // default: undefined
        show: true, // default
        alignedAxis: root.$Cesium.Cartesian3.clone(root.$Cesium.Cartesian3.UNIT_Z),
        width: 40, // default: undefined
        height: 40, // default: undefined
      }
    })
    AddFolderGroup(point)
  }

  function AddOverlayGroup (id: string, overlayGroup: any) {
    coverMap[id] = [...(coverMap[id] || []), overlayGroup]
  }
  function AddWaylineGroup (overlayGroup: any) {
    waylineList.push(overlayGroup)
  }
  function AddFolderGroup (overlayGroup: any) {
    coverFolderMap.push(overlayGroup)
  }

  function initPolyline (id: string, name: string, coordinates:GeojsonCoordinate[], color?:string, data?:{}) {
    const path = [] as any[]
    coordinates.forEach(coordinate => {
      path.push(coordinate[0], coordinate[1])
    })
    const polyline = viewer.entities.add({
      name: 'polyline',
      polyline: {
        show: true, // 是否显示，默认显示
        positions: Cesium.Cartesian3.fromDegreesArray(path),
        width: 3, // 线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.fromCssColorString(color || normalColor), // 线的颜色，默认为白色
        // depthFailMaterial: Cesium.Color.RED, //线被遮挡部分的颜色，默认为空（不显示被遮挡的部分），设置后可显示被遮挡的部分
        arcType: Cesium.ArcType.NONE, // 线的地理类型，NONE表示纯直线，GEODESIC表示为测地线，RHUMB表示等角航线，默认为测地线
        // arcType: Cesium.ArcType.GEODESIC,
        // arcType: Cesium.ArcType.RHUMB,
        clampToGround: false, // 是否贴地，默认为否
        shadows: Cesium.ShadowMode.DISABLED, // 是否显示光照阴影，默认为否
        // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100.0, 2000000.0), //显示的视角距离条件，在该范围内显示，范围不显示，默认为空
        classificationType: Cesium.ClassificationType.BOTH,
        zIndex: 10, // 显示深度，越大表示图层在上
      }
    })
    AddOverlayGroup(id, polyline)
  }

  function draw2DPin (name: string, coordinates: WaylinePoint, color?: string, data?: {}) {
    const markerContent = '<div style="display: flex;align-items: center;justify-content: center;width: 28px!important;height: 28px!important;background-color: #037aff;border: 2px solid #fff;border-radius: 50%;">' + (coordinates.index + 1) + '</div>'
    const pin = new AMap.Marker({
      position: new AMap.LngLat(coordinates.lng, coordinates.lat),
      // icon: getPinIcon(color),
      anchor: 'center',
      cursor: 'move',
      // offset: [-15, -15],
      // label: {
      //   content: coordinates.index + 1,
      //   offset: [-25, -25]
      // },
      content: markerContent,
      extData: data,
      draggable: false,
    })
    AddWaylineGroup(pin)
  }

  function drawStart2DPin (name: string, coordinates: WaylinePoint, color?: string, data?: {}) {
    const markerContent = '<div style="display: flex;align-items: center;justify-content: center;width: 28px!important;height: 28px!important;background-color: #049c28;border: 2px solid #fff;border-radius: 50%;color:#fff;font-weight: 500;">' + data.name + '</div>'
    const pin = new AMap.Marker({
      position: new AMap.LngLat(coordinates.lng, coordinates.lat),
      anchor: 'center',
      // cursor: 'move',
      content: markerContent,
      extData: data,
      draggable: false,
    })
    AddWaylineGroup(pin)
  }

  function drawEnd2DPin (name: string, coordinates: WaylinePoint, color?: string, data?: {}) {
    const markerContent = '<div style="display: flex;align-items: center;justify-content: center;width: 28px!important;height: 28px!important;background-color: #ff6701;border: 2px solid #fff;border-radius: 50%;color:#fff;font-weight: 500;">' + data.name + '</div>'
    const pin = new AMap.Marker({
      position: new AMap.LngLat(coordinates.lng, coordinates.lat),
      anchor: 'center',
      // cursor: 'move',
      content: markerContent,
      extData: data,
      draggable: false,
    })
    AddWaylineGroup(pin)
  }

  function draw2DPinbyTransResult (name: string, coordinates:GeojsonCoordinate, color?:string, data?:{}) {
    const markerContent = '<div style="display: flex;align-items: center;justify-content: center;width: 28px!important;height: 28px!important;background-color: #037aff;color: #fff; border: 2px solid #fff;border-radius: 50%;">' + (data?.id) + '</div>'
    const pin = new AMap.Marker({
      position: new AMap.LngLat(coordinates[0], coordinates[1]),
      // icon: getPinIcon(color),
      anchor: 'center',
      // offset: [-15, -15],
      // label: {
      //   content: data?.id,
      //   offset: [-25, -25]
      // },
      content: markerContent,
      extData: data,
      zIndex: 10
    })
    AddWaylineGroup(pin)
  }

  function drawPolyline (kml: WaylinePoint[], wpml: WaylinePoint[], color?: string, data?: {}) {
    removeWaylineFromMap()
    const path = [] as any[]
    // kml.forEach((coordinate, index) => {
    //   draw2DPin(index.toString(), coordinate, 'point', {
    //     id: index + 1,
    //     name: index + 1,
    //   })
    // })
    wpml.forEach((coordinate, index) => {
      // const transResult = wgs84togcj02(
      //   coordinate.lng,
      //   coordinate.lat
      // ) as GeojsonCoordinate
      // path.push(new AMap.LngLat(transResult[0], transResult[1]))
      // draw2DPinbyTransResult(index.toString(), transResult, 'point', {
      //   id: index + 1,
      //   name: index + 1,
      // })
      // polylines.add({
      //   positions : Cesium.Cartesian3.fromDegreesArray([coordinate.lng, coordinate.lat]),
      //   width : 3,
      //   show:true
      // })
      path.push(coordinate.lng, coordinate.lat)
      const point = viewer.entities.add({
        position: Cesium.Cartesian3.fromDegrees(coordinate.lng, coordinate.lat),
        point: {
          pixelSize: 30,
          color: Cesium.Color.fromCssColorString(blueColor), // 设置颜色并设置透明度,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 2,
        },
        label: {
          text: Number(index + 1) + '',
          font: '18px sans-serif',
          fillColor: Cesium.Color.WHITE,
          outlineColor: Cesium.Color.WHITE,
          outlineWidth: 1,
          style: Cesium.LabelStyle.FILL_AND_OUTLINE,
          verticalOrigin: Cesium.VerticalOrigin.BOTTOM,
          pixelOffset: new Cesium.Cartesian2(0, -20),
        },
      })
      AddWaylineGroup(point)
    })
    const polyline = viewer.entities.add({
      name: 'polyline',
      polyline: {
        show: true, // 是否显示，默认显示
        positions: Cesium.Cartesian3.fromDegreesArray(path),
        width: 5, // 线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.fromCssColorString(greenColor), // 线的颜色，默认为白色
        // depthFailMaterial: Cesium.Color.RED, //线被遮挡部分的颜色，默认为空（不显示被遮挡的部分），设置后可显示被遮挡的部分
        arcType: Cesium.ArcType.NONE, // 线的地理类型，NONE表示纯直线，GEODESIC表示为测地线，RHUMB表示等角航线，默认为测地线
        // arcType: Cesium.ArcType.GEODESIC,
        // arcType: Cesium.ArcType.RHUMB,
        clampToGround: false, // 是否贴地，默认为否
        shadows: Cesium.ShadowMode.DISABLED, // 是否显示光照阴影，默认为否
        // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100.0, 2000000.0), //显示的视角距离条件，在该范围内显示，范围不显示，默认为空
        classificationType: Cesium.ClassificationType.BOTH,
        zIndex: 10, // 显示深度，越大表示图层在上
      }
    })
    AddWaylineGroup(polyline)
    viewer.zoomTo(polyline, new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0), Cesium.Math.toRadians(-90.0), Cesium.Math.toRadians(0.0)))
    // const polyline = new AMap.Polyline({
    //   path: path,
    //   strokeColor: color || greenColor,
    //   strokeOpacity: 1,
    //   strokeWeight: 6,
    //   strokeStyle: 'solid',
    //   showDir: true,
    //   // isOutline: true,
    //   // lineJoin: 'round',
    //   // lineCap: 'round',
    //   extData: data
    //   // draggable: true,
    // })

    // AddWaylineGroup(polyline)
    // root.$map.setFitView(polyline)
  }

  function drawPolylineNoPoint (wpml: WaylinePoint[], color?: string, data?: {}) {
    removeWaylineFromMap()
    const path = [] as GeojsonCoordinate[]
    wpml.forEach((coordinate, index) => {
      const transResult = wgs84togcj02(
        coordinate.lng,
        coordinate.lat
      ) as GeojsonCoordinate
      path.push(new AMap.LngLat(transResult[0], transResult[1]))
    })
    const polyline = new AMap.Polyline({
      path: path,
      strokeColor: color || greenColor,
      strokeOpacity: 1,
      strokeWeight: 6,
      strokeStyle: 'solid',
      showDir: true,
      extData: data
    })

    AddWaylineGroup(polyline)
    root.$map.setFitView(polyline)
  }

  function drawEditPolyline (wpml: WaylinePoint[], color?: string, data?: {}) {
    removeWaylineFromMap()
    const path = [] as GeojsonCoordinate[]
    wpml.forEach((item, index) => {
      path.push(new AMap.LngLat(item.lng, item.lat))
      // draw2DPin(index.toString(), item, 'point', {
      //   id: index,
      //   name: index,
      // })
    })
    const polyline = new AMap.Polyline({
      path: path,
      strokeColor: color || greenColor,
      strokeOpacity: 1,
      strokeWeight: 6,
      strokeStyle: 'solid',
      showDir: true,
      extData: data
    })

    AddWaylineGroup(polyline)
    root.$map.setFitView(polyline)
  }

  function getDistanceOfLine (wpml: WaylinePoint[]) {
    // 返回线段 p0-p1-p2 的实际长度，单位：米
    const path = [] as GeojsonCoordinate[]
    wpml.forEach(item => {
      const arr = [item.lng, item.lat] as GeojsonCoordinate
      path.push(arr)
    })
    const dis = AMap.GeometryUtil.distanceOfLine(path)
    return dis
  }

  function getDistancePolyline (wpml: WaylinePoint[]) {
    let totalDistance = 0
    for (let i = 0; i < wpml.length - 1; i++) {
      const startPoint = Cesium.Cartesian3.fromDegrees(wpml[i].lng, wpml[i].lat)
      const endPoint = Cesium.Cartesian3.fromDegrees(wpml[i + 1].lng, wpml[i + 1].lat)
      const startCartographic = Cesium.Cartographic.fromCartesian(startPoint)
      const endCartographic = Cesium.Cartographic.fromCartesian(endPoint)
      // 计算地球表面两点之间的距离
      const geodesic = new Cesium.EllipsoidGeodesic()
      geodesic.setEndPoints(startCartographic, endCartographic)
      totalDistance += geodesic.surfaceDistance
    }
    return totalDistance
  }

  function getRingArea (wpml: WaylinePoint[]) {
    // 计算一个经纬度路径围成区域的实际面积。单位：平米
    const path = [] as GeojsonCoordinate[]
    wpml.forEach(item => {
      const arr = [item.lng, item.lat] as GeojsonCoordinate
      path.push(arr)
    })
    const dis = AMap.GeometryUtil.ringArea(path)
    return dis
  }

  function drawPolygon (kml: WaylinePoint[], wpml: WaylinePoint[], color?: string, data?: {}) {
    removeWaylineFromMap()
    const path = [] as any[]
    const path2 = [] as any[]
    kml.forEach((coordinate, index) => {
      path.push(coordinate.lng, coordinate.lat)
      // const point = viewer.entities.add({
      //   position: Cesium.Cartesian3.fromDegrees(coordinate.lng, coordinate.lat),
      //   point: {
      //     pixelSize: 25,
      //     color: new Cesium.Color.fromCssColorString(blueColor), // 设置颜色并设置透明度,
      //     outlineColor: Cesium.Color.WHITE,
      //     outlineWidth: 2,
      //     // zIndex: 100
      //     eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
      //   },
      //   label: {
      //     text: Number(index + 1) + '',
      //     font: '18px sans-serif',
      //     fillColor: Cesium.Color.WHITE,
      //     outlineColor: Cesium.Color.WHITE,
      //     outlineWidth: 1,
      //     style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      //     heightReference: Cesium.HeightReference.NONE,
      //     verticalOrigin: Cesium.VerticalOrigin.TOP,
      //     pixelOffset: new Cesium.Cartesian2(0, -35),
      //     // zIndex: 101,
      //   },
      // })
      // AddWaylineGroup(point)
    })

    const polygon = viewer.entities.add({
      polygon: {
        show: true, // 是否显示，默认显示
        hierarchy: Cesium.Cartesian3.fromDegreesArray(path),
        height: 0.0, // height is required for outline to display
        // 配置填充面材质
        material: Cesium.Color.fromCssColorString(yelloColor).withAlpha(0.3),
        // 配置边框线
        outline: true,
        outlineWidth: 2,
        outlineColor: Cesium.Color.fromCssColorString(yelloColor),
      },
    })
    AddWaylineGroup(polygon)
    viewer.zoomTo(polygon, new Cesium.HeadingPitchRange(Cesium.Math.toRadians(0), Cesium.Math.toRadians(-90.0), Cesium.Math.toRadians(0.0)))

    wpml.forEach((coordinate, index) => {
      path2.push(coordinate.lng, coordinate.lat)
      if (index === 0) {
        const point = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(coordinate.lng, coordinate.lat),
          // point: {
          //   pixelSize: 25,
          //   color: new Cesium.Color.fromCssColorString(blueColor), // 设置颜色并设置透明度,
          //   outlineColor: Cesium.Color.WHITE,
          //   outlineWidth: 2,
          //   // zIndex: 100
          //   eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
          // },
          label: {
            text: '起',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 1,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            heightReference: Cesium.HeightReference.NONE,
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            pixelOffset: new Cesium.Cartesian2(0, -35),
            // zIndex: 101,
          },
        })
        AddWaylineGroup(point)
      } else if (index === wpml.length - 1) {
        const point = viewer.entities.add({
          position: Cesium.Cartesian3.fromDegrees(coordinate.lng, coordinate.lat),
          // point: {
          //   pixelSize: 25,
          //   color: new Cesium.Color.fromCssColorString(blueColor), // 设置颜色并设置透明度,
          //   outlineColor: Cesium.Color.WHITE,
          //   outlineWidth: 2,
          //   // zIndex: 100
          //   eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
          // },
          label: {
            text: '终',
            font: '18px sans-serif',
            fillColor: Cesium.Color.WHITE,
            outlineColor: Cesium.Color.WHITE,
            outlineWidth: 1,
            style: Cesium.LabelStyle.FILL_AND_OUTLINE,
            heightReference: Cesium.HeightReference.NONE,
            verticalOrigin: Cesium.VerticalOrigin.TOP,
            pixelOffset: new Cesium.Cartesian2(0, -35),
            // zIndex: 101,
          },
        })
        AddWaylineGroup(point)
      }
      // const point = viewer.entities.add({
      //   position: Cesium.Cartesian3.fromDegrees(coordinate.lng, coordinate.lat),
      //   point: {
      //     pixelSize: 25,
      //     color: new Cesium.Color.fromCssColorString(blueColor), // 设置颜色并设置透明度,
      //     outlineColor: Cesium.Color.WHITE,
      //     outlineWidth: 2,
      //     // zIndex: 100
      //     eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0),
      //   },
      //   label: {
      //     text: Number(index + 1) + '',
      //     font: '18px sans-serif',
      //     fillColor: Cesium.Color.WHITE,
      //     outlineColor: Cesium.Color.WHITE,
      //     outlineWidth: 1,
      //     style: Cesium.LabelStyle.FILL_AND_OUTLINE,
      //     heightReference: Cesium.HeightReference.NONE,
      //     verticalOrigin: Cesium.VerticalOrigin.TOP,
      //     pixelOffset: new Cesium.Cartesian2(0, -35),
      //     // zIndex: 101,
      //   },
      // })
      // AddWaylineGroup(point)
    })
    const polyline = viewer.entities.add({
      polyline: {
        show: true, // 是否显示，默认显示
        positions: Cesium.Cartesian3.fromDegreesArray(path2),
        width: 5, // 线的宽度（像素），默认为1
        granularity: Cesium.Math.RADIANS_PER_DEGREE,
        material: Cesium.Color.fromCssColorString(greenColor), // 线的颜色，默认为白色
        // depthFailMaterial: Cesium.Color.RED, //线被遮挡部分的颜色，默认为空（不显示被遮挡的部分），设置后可显示被遮挡的部分
        arcType: Cesium.ArcType.NONE, // 线的地理类型，NONE表示纯直线，GEODESIC表示为测地线，RHUMB表示等角航线，默认为测地线
        // arcType: Cesium.ArcType.GEODESIC,
        // arcType: Cesium.ArcType.RHUMB,
        clampToGround: false, // 是否贴地，默认为否
        shadows: Cesium.ShadowMode.DISABLED, // 是否显示光照阴影，默认为否
        // distanceDisplayCondition: new Cesium.DistanceDisplayCondition(100.0, 2000000.0), //显示的视角距离条件，在该范围内显示，范围不显示，默认为空
        classificationType: Cesium.ClassificationType.BOTH,
        zIndex: 10, // 显示深度，越大表示图层在上
      },
    })
    AddWaylineGroup(polyline)

    // wpml.forEach((coordinate, index) => {
    //   const transResult = wgs84togcj02(
    //     coordinate.lng,
    //     coordinate.lat
    //   ) as GeojsonCoordinate
    //   path2.push(new AMap.LngLat(transResult[0], transResult[1]))
    //   // draw2DPinbyTransResult(index.toString(), transResult, 'point', {
    //   //   id: index,
    //   //   name: index,
    //   // })
    //   const coord = {
    //     index: index,
    //     lng: transResult[0],
    //     lat: transResult[1]
    //   } as WaylinePoint
    //   if (index === 0) {
    //     drawStart2DPin(index.toString(), coord, 'point', {
    //       id: 'start',
    //       name: '起',
    //     })
    //   } else if (index === wpml.length - 1) {
    //     drawEnd2DPin(index.toString(), coord, 'point', {
    //       id: 'end',
    //       name: '终',
    //     })
    //   }
    // })

    // const Polygon = new AMap.Polygon({
    //   path: path,
    //   strokeColor: color || yelloColor,
    //   strokeOpacity: 1,
    //   strokeWeight: 6,
    //   fillColor: color || yelloColor,
    //   fillOpacity: 0.4,
    //   // draggable: true,
    //   extData: data
    // })

    // const polyline = new AMap.Polyline({
    //   path: path2,
    //   strokeColor: color || greenColor,
    //   strokeOpacity: 1,
    //   strokeWeight: 6,
    //   strokeStyle: 'solid',
    //   // isOutline: true,
    //   // lineJoin: 'round',
    //   // lineCap: 'round',
    //   showDir: true,
    //   extData: data
    //   // draggable: true,
    // })

    // AddWaylineGroup([Polygon, polyline])
    // // AddWaylineGroup(Polygon)
    // root.$map.setFitView(Polygon)
  }

  function lngLatToContainer (lon: number, lat:number) {
    return root.$map.lngLatToContainer(new AMap.LngLat(lon, lat))
    // return root.$map.lngLatToPixel(new AMap.LngLat(lon, lat), 15)
  }

  function containerToLngLat (px: number, py:number) {
    return root.$map.containerToLngLat(new AMap.Pixel(px, py))
    // return root.$map.pixelToLngLat(new AMap.Pixel(px, py), 15)
  }

  function lngLatToCoords (lon: number, lat:number) {
    return root.$map.lngLatToCoords(new AMap.LngLat(lon, lat))
  }

  function coordsToLngLat (px: number, py:number) {
    return root.$map.coordsToLngLat([px, py])
  }

  function drawEditPolygon (kml: WaylinePoint[], wpml: WaylinePoint[], color?: string, data?: {}) {
    removeWaylineFromMap()
    const path = [] as GeojsonCoordinate[]
    const path2 = [] as GeojsonCoordinate[]
    kml.forEach((coordinate, index) => {
      // console.log('transResult=======', transResult, coordinate)
      path.push(new AMap.LngLat(coordinate.lng, coordinate.lat))
      // draw2DPin(index.toString(), coordinate, 'point', {
      //   id: index,
      //   name: index,
      // })
    })

    wpml.forEach((coordinate, index0) => {
      path2.push(new AMap.LngLat(coordinate.lng, coordinate.lat))
      // if (index === 0) {
      //   drawStart2DPin(index.toString(), coordinate, 'point', {
      //     id: 'start',
      //     name: '起',
      //   })
      // } else if (index === wpml.length - 1) {
      //   drawEnd2DPin(index.toString(), coordinate, 'point', {
      //     id: 'end',
      //     name: '终',
      //   })
      // }
      // 调试临时用
      coordinate.index = coordinate.index - 1
      draw2DPin(coordinate.index.toString(), coordinate, 'point', {
        id: coordinate.index,
        name: coordinate.index,
      })
    })

    const Polygon = new AMap.Polygon({
      path: path,
      strokeColor: color || yelloColor,
      strokeOpacity: 1,
      strokeWeight: 6,
      fillColor: color || yelloColor,
      fillOpacity: 0.4,
      // draggable: true,
      extData: data
    })

    const polyline = new AMap.Polyline({
      path: path2,
      strokeColor: color || greenColor,
      strokeOpacity: 1,
      strokeWeight: 6,
      strokeStyle: 'solid',
      // isOutline: true,
      // lineJoin: 'round',
      // lineCap: 'round',
      showDir: true,
      // extData: data
      // draggable: true,
    })

    AddWaylineGroup([Polygon, polyline])
    root.$map.setFitView(Polygon)
  }

  function initPolygon (id: string, name: string, coordinates:GeojsonCoordinate[][], color?:string, data?:{}) {
    const path = [] as any[]
    coordinates[0].forEach(coordinate => {
      path.push(coordinate[0], coordinate[1])
    })
    const polygon = viewer.entities.add({
      polygon: {
        show: true, // 是否显示，默认显示
        id: id,
        hierarchy: Cesium.Cartesian3.fromDegreesArray(path),
        height: 0.0, // height is required for outline to display
        // 配置填充面材质
        material: Cesium.Color.fromCssColorString(color || normalColor).withAlpha(0.3),
        // 配置边框线
        outline: true,
        outlineWidth: 2,
        outlineColor: Cesium.Color.fromCssColorString(color || normalColor),
      },
    })
    AddOverlayGroup(id, polygon)
    // console.log('Polygon', path)
    // const Polygon = new AMap.Polygon({
    //   path: path,
    //   strokeOpacity: 1,
    //   strokeWeight: 2,
    //   fillColor: color || normalColor,
    //   fillOpacity: 0.4,
    //   // draggable: true,
    //   strokeColor: color || normalColor,
    //   extData: data
    // })
    // AddOverlayGroup(Polygon)
  }

  function removeCoverFromMap (layerId:string) {
    coverMap[layerId].forEach(cover => root.$viewer.entities.remove(cover))
    coverMap[layerId] = []
    // console.log('removeCoverFromMap', layerId)
  }

  function removeAllCoverFromMap () {
    for (let i = 0; i < coverList.length; i++) {
      const ele = coverList[i]
      root.$map.remove(ele)
      coverList.slice(i, 1)
    }
  }

  function removeWaylineFromMap () {
    for (let i = 0; i < waylineList.length; i++) {
      const ele = waylineList[i]
      viewer.entities.remove(ele)
      waylineList.slice(i, 1)
    }
  }

  function removeFolderFromMap () {
    for (let i = 0; i < coverFolderMap.length; i++) {
      const ele = coverFolderMap[i]
      root.$viewer.entities.remove(ele)
      coverFolderMap.slice(i, 1)
    }
  }

  function getElementFromMap (layerId:string) {
    return coverMap[layerId]
  }

  function updatePinElement (layerId:string, name: string, coordinates:GeojsonCoordinate, color?:string) {
    const elements = getElementFromMap(layerId)
    if (elements && elements.length > 0) {
      const icon = getPinIcon(color)
      elements.forEach(elem => {
        elem.label.text = name
        elem.position = root.$Cesium.Cartesian3.fromDegrees(coordinates[0], coordinates[1])
        elem.billboard.image = icon
      })
    } else {
      const icon = getPinIcon(color)
      init2DPin(layerId, name, coordinates, icon, {
        id: layerId,
        name: name
      })
    }
  }

  function updatePolylineElement (id:string, name: string, coordinates:GeojsonCoordinate[], color?:string) {
    const elements = getElementFromMap(id)
    if (elements && elements.length > 0) {
      const element = elements[0]
      element.polyline.material = root.$Cesium.Color.fromCssColorString(color)
    } else {
      initPolyline(id, name, coordinates, color, {
        id: id,
        name: name
      })
    }
  }

  function updatePolygonElement (id:string, name: string, coordinates:GeojsonCoordinate[][], color?:string) {
    const elements = getElementFromMap(id)
    if (elements && elements.length > 0) {
      const element = elements[0]
      element.polygon.material = root.$Cesium.Color.fromCssColorString(color).withAlpha(0.3)
      element.polygon.outlineColor = root.$Cesium.Color.fromCssColorString(color)
      console.log('updatePolygonElement', elements)
    } else {
      initPolygon(id, name, coordinates, color, {
        id: id,
        name: name
      })
    }
  }

  function initTextInfo (content: string, coordinates: GeojsonCoordinate, id: string) {
    const info = new AMap.Text({
      text: content,
      position: new AMap.LngLat(coordinates[0], coordinates[1]),
      extData: { id: id, type: 'text' },
      anchor: 'top-center',
      style: {
        background: 'none',
        borderStyle: 'none',
        fontSize: '16px',
      },
    })
    AddOverlayGroup(id, info)
  }

  function initFlightAreaCircle (name: string, radius: number, position: GeojsonCoordinate, data: { id: string, type: EFlightAreaType, enable: boolean }) {
    const circle = new AMap.Circle({
      strokeColor: data.enable ? flightAreaColorMap[data.type] : disableColor,
      strokeOpacity: 1,
      strokeWeight: 6,
      extData: data,
      bubble: true,
      strokeStyle: 'dashed',
      strokeDasharray: EFlightAreaType.NFZ === data.type ? [10, 2] : [10, 1, 2],
      fillColor: flightAreaColorMap[data.type],
      fillOpacity: EFlightAreaType.NFZ === data.type && data.enable ? 0.3 : 0,
      radius: radius,
      center: new AMap.LngLat(position[0], position[1]),
    })
    AddOverlayGroup(data.id, circle)
    initTextInfo(name, position, data.id)
  }

  function updateFlightAreaCircle (id: string, name: string, radius: number, position: GeojsonCoordinate, enable: boolean, type: EFlightAreaType) {
    const elements = getElementFromMap(id)
    if (elements && elements.length > 0) {
      let textIndex = elements.findIndex(ele => ele.getExtData()?.type === 'text')
      if (textIndex === -1) {
        textIndex = 1
        initTextInfo(name, position, id)
      } else {
        const text = elements[textIndex]
        text.setText(name)
        text.setPosition(position)
      }
      const element = elements[textIndex ^ 1]
      const options = element.getOptions()

      options.fillOpacity = EFlightAreaType.NFZ === type && enable ? 0.3 : 0
      options.strokeColor = enable ? flightAreaColorMap[type] : disableColor
      options.radius = radius
      options.center = new AMap.LngLat(position[0], position[1])
      element.setOptions(options)
    } else {
      initFlightAreaCircle(name, radius, position, { id, type, enable })
    }
  }

  function calcPolygonPosition (coordinate: GeojsonCoordinate[]): GeojsonCoordinate {
    const index = coordinate.length - 1
    return [(coordinate[0][0] + coordinate[index][0]) / 2.0, (coordinate[0][1] + coordinate[index][1]) / 2]
  }

  function initFlightAreaPolygon (name: string, coordinates: GeojsonCoordinate[], data: { id: string, type: EFlightAreaType, enable: boolean }) {
    const path = [] as GeojsonCoordinate[]
    coordinates.forEach(coordinate => {
      path.push(new AMap.LngLat(coordinate[0], coordinate[1]))
    })
    const polygon = new AMap.Polygon({
      path: path,
      strokeColor: data.enable ? flightAreaColorMap[data.type] : disableColor,
      strokeOpacity: 1,
      strokeWeight: 4,
      draggable: true,
      extData: data,
      strokeStyle: 'dashed',
      strokeDasharray: EFlightAreaType.NFZ === data.type ? [10, 2] : [10, 1, 2],
      fillColor: flightAreaColorMap[data.type],
      fillOpacity: EFlightAreaType.NFZ === data.type && data.enable ? 0.3 : 0,
    })
    AddOverlayGroup(data.id, polygon)
    initTextInfo(name, calcPolygonPosition(coordinates), data.id)
  }

  function updateFlightAreaPolygon (id: string, name: string, coordinates: GeojsonCoordinate[], enable: boolean, type: EFlightAreaType) {
    const elements = getElementFromMap(id)
    if (elements && elements.length > 0) {
      let textIndex = elements.findIndex(ele => ele.getExtData()?.type === 'text')
      if (textIndex === -1) {
        textIndex = 1
        initTextInfo(name, calcPolygonPosition(coordinates), id)
      } else {
        const text = elements[textIndex]
        text.setText(name)
        text.setPosition(calcPolygonPosition(coordinates))
      }
      const element = elements[textIndex ^ 1]
      const options = element.getOptions()
      const path = [] as GeojsonCoordinate[]
      coordinates.forEach(coordinate => {
        path.push(new AMap.LngLat(coordinate[0], coordinate[1]))
      })
      options.path = path
      options.fillOpacity = EFlightAreaType.NFZ === type && enable ? 0.3 : 0
      options.strokeColor = enable ? flightAreaColorMap[type] : disableColor
      element.setOptions(options)
    } else {
      initFlightAreaPolygon(name, coordinates, { id, type, enable })
    }
  }

  function setPolylineEditer (id:string) {
    let currentPolyline
    for (let i = 0; i < waylineList.length; i++) {
      const ele = waylineList[i]
      const extdata = ele._opts?.extData
      if (extdata?.id === id) {
        currentPolyline = ele
        // break
      }
    }
    const polyEditor = new root.$aMap.PolylineEditor(root.$map, currentPolyline, {
      editOptions: {
        strokeColor: greenColor,
        strokeOpacity: 1,
        strokeWeight: 6,
        strokeStyle: 'solid',
        showDir: true,
        isOutline: false,
        borderWeight: 0
      },
      controlPoint: {
        strokeColor: '#FFF',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#037aff',
        radius: 12
      }
    })
    polyEditor.open()
    polyEditor.on(['adjust', 'addnode', 'removenode', 'end'], (data) => {
      const coordinates = data.target._opts.path as GeojsonCoordinate[]
      store.commit('SET_WAYLINEPOINT', coordinates)
    })
  }

  function setPolygonEditer (id:string) {
    let currentPolyline
    for (let i = 0; i < waylineList.length; i++) {
      const ele = waylineList[i]
      // console.log(ele)
      for (let y = 0; y < ele.length; y++) {
        const ele2 = ele[y]
        // console.log(ele2)
        const extdata = ele2._opts.extData
        // console.log('extdata======', extdata)
        if (extdata?.id === id) {
          currentPolyline = ele[y]
          // break
        }
      }
    }
    // console.log('========currentPolyline========', currentPolyline, store.state.waylineList)
    const polyEditor = new root.$aMap.PolygonEditor(root.$map, currentPolyline, {
      editOptions: {
        strokeColor: yelloColor,
        strokeOpacity: 1,
        strokeWeight: 6,
        fillColor: yelloColor,
        fillOpacity: 0.4,
      },
      controlPoint: {
        strokeColor: '#FFF',
        strokeOpacity: 1,
        strokeWeight: 2,
        fillColor: '#037aff',
        radius: 12
      }
    })
    polyEditor.open()
    polyEditor.on(['adjust', 'addnode', 'removenode', 'end'], (data) => {
      const coordinates = data.target._opts.path as GeojsonCoordinate[]
      // console.log('postMappingResource=========', coordinates)
      store.commit('SET_WAYLINEPOINTEDITER', coordinates)
      // console.log('=================', coordinates)
    })
  }

  return {
    init2DPin,
    initPolyline,
    drawPolyline,
    drawEditPolyline,
    drawPolylineNoPoint,
    drawPolygon,
    containerToLngLat,
    lngLatToContainer,
    coordsToLngLat,
    lngLatToCoords,
    drawEditPolygon,
    initPolygon,
    getDistanceOfLine,
    getDistancePolyline,
    getRingArea,
    removeCoverFromMap,
    removeAllCoverFromMap,
    removeWaylineFromMap,
    getElementFromMap,
    updatePinElement,
    updatePolylineElement,
    updatePolygonElement,
    initFlightAreaCircle,
    initFlightAreaPolygon,
    updateFlightAreaPolygon,
    updateFlightAreaCircle,
    calcPolygonPosition,
    setPolylineEditer,
    setPolygonEditer,
    init2DPinFolder,
    removeFolderFromMap
  }
}
