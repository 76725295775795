<template>
  <div class="create-plan-wrapper">
    <div class="header">
      新建航线
    </div>
    <div class="content">
      <a-form ref="valueRef" layout="vertical" :hideRequiredMark="true" :rules="rules" :model="waylineBody" labelAlign="left">
        <a-form-item label="航线名称" name="name" :labelCol="{span: 23}">
          <a-input style="background: black;" :maxlength="20" placeholder="请输入航线名称" v-model:value="waylineBody.name"/>
        </a-form-item>
        <a-form-item label="航线类型" name="template_types" :labelCol="{span: 23}">
          <a-select style="background: black;" placeholder="请选择航线类型" v-model:value="waylineBody.template_types">
            <a-select-option value='0'>航点航线</a-select-option>
            <a-select-option value='1'>面状航线</a-select-option>
            <!-- <a-select-option value='2'>倾斜摄影</a-select-option> -->
            <!-- <a-select-option value='3'>航带飞行</a-select-option> -->
            <!-- <a-select-option value='4'>几何体航线</a-select-option> -->
            <!-- <a-select-option value='5'>斜面航线</a-select-option> -->
            <!-- <a-select-option value='6'>环绕航线</a-select-option> -->
          </a-select>
        </a-form-item>
        <a-form-item label="选择飞行器与负载" name="type" :labelCol="{span: 23}">
          <a-select style="background: black;" placeholder="请选择飞行器与负载" @select="onTypeSelect" v-model:value="waylineBody.type">
            <a-select-option value='0'>M30系列</a-select-option>
            <a-select-option value='1'>Mavic 3 行业系列</a-select-option>
            <a-select-option value='2'>Matrice 3D 系列</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="选择负载" name="payload_model_keys" :labelCol="{span: 23}">
          <a-select style="background: black;" placeholder="请选择负载" v-model:value="waylineBody.payload_model_keys" @select="onSelectPayload">
            <a-select-option v-if="waylineBody.type === '0'" :value='EDeviceType.M30_Camera'>M30 相机</a-select-option>
            <a-select-option v-if="waylineBody.type === '0'" :value='EDeviceType.M30T_Camera'>M30T 相机</a-select-option>
            <a-select-option v-if="waylineBody.type === '1'" :value='EDeviceType.M3E_Camera'>Mavic 3E 相机</a-select-option>
            <a-select-option v-if="waylineBody.type === '1'" :value='EDeviceType.M3T_Camera'>Mavic 3T 相机</a-select-option>
            <a-select-option v-if="waylineBody.type === '2'" :value='EDeviceType.M3D_Camera'>M3D 相机</a-select-option>
            <a-select-option v-if="waylineBody.type === '2'" :value='EDeviceType.M3TD_Camera'>M3TD 相机</a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item class="width-100" style="margin-bottom: 40px;">
          <div class="footer">
            <a-button class="mr10" style="background: #3c3c3c;" @click="closeWayline">取消
            </a-button>
            <a-button type="primary" @click="onSubmit" :disabled="disabled">确定
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, toRaw, UnwrapRef } from 'vue'
import { CloseOutlined, RocketOutlined, CameraFilled, UserOutlined, PlusCircleOutlined, MinusCircleOutlined, WalletFilled } from '@ant-design/icons-vue'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { useMyStore } from '/@/store'
import { WaylineType, WaylineFile } from '/@/types/wayline'
import { Device, EDeviceType } from '/@/types/device'
import { createWayline } from '/@/api/wayline'
import { getRoot } from '/@/root'
import { TaskType, OutOfControlActionOptions, OutOfControlAction, TaskTypeOptions } from '/@/types/task'
import moment, { Moment } from 'moment'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import type { Rule } from 'ant-design-vue/es/form/interface'
import { useMapTool } from '/@/hooks/use-map-tool'

const root = getRoot()
const store = useMyStore()
const useMapToolHook = useMapTool()

const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!

const wayline = computed<WaylineFile>(() => {
  return store.state.waylineInfo
})

const dock = computed<Device>(() => {
  return store.state.dockInfo
})

interface SelectOption {
  value: any,
  label: string,
  more?: any
}
const disabled = ref(false)
const routeName = ref('')
const waylineBody = reactive({
  name: '',
  type: '2',
  wayline_id: '',
  drone_model_key: EDeviceType.M3TD.toString(),
  payload_model_keys: EDeviceType.M3TD_Camera,
  template_types: '0',
  algorithm_list: ''
})

const drawerVisible = ref(false)
const valueRef = ref()

const validateName = async (_rule: Rule, value: string) => {
  if (value === '') {
    return Promise.reject(new Error('请输入航线名称.'))
  } else {
    const regex = /^[^<>:"/|?*._\\]+$/
    if (!regex.test(value)) {
      return Promise.reject(new Error('航线名称不能包含特殊字符 <>:"/|?*._\\'))
    }
    return Promise.resolve()
  }
}

const rules = {
  // name: [{ required: true, message: '请输入航线名称.' }],
  name: [{ validator: validateName, trigger: 'change' }],
  type: [{ required: true, message: '请选择飞行器与负载' }],
  payload_model_keys: [{ required: true, message: '请选择负载' }],
  template_types: [{ required: true, message: '请选择航线类型' }],
}

const onTypeSelect = (val: String) => {
  console.log('onTypeSelect', val)
  if (val === '0') {
    waylineBody.drone_model_key = EDeviceType.M30.toString()
    waylineBody.payload_model_keys = EDeviceType.M30_Camera
  } else if (val === '1') {
    waylineBody.drone_model_key = EDeviceType.M3E.toString()
    waylineBody.payload_model_keys = EDeviceType.M3E_Camera
  } else if (val === '2') {
    waylineBody.drone_model_key = EDeviceType.M3D.toString()
    waylineBody.payload_model_keys = EDeviceType.M3D_Camera
  }
}

function onSelectPayload (val: any) {
  if (val === EDeviceType.M30_Camera) {
    waylineBody.drone_model_key = EDeviceType.M30.toString()
  } else if (val === EDeviceType.M30T_Camera) {
    waylineBody.drone_model_key = EDeviceType.M30T.toString()
  } else if (val === EDeviceType.M3E_Camera) {
    waylineBody.drone_model_key = EDeviceType.M3E.toString()
  } else if (val === EDeviceType.M3T_Camera) {
    waylineBody.drone_model_key = EDeviceType.M3T.toString()
  } else if (val === EDeviceType.M3D_Camera) {
    waylineBody.drone_model_key = EDeviceType.M3D.toString()
  } else if (val === EDeviceType.M3TD_Camera) {
    waylineBody.drone_model_key = EDeviceType.M3TD.toString()
  }
}

function onSubmit () {
  valueRef.value.validate().then(() => {
    disabled.value = true
    createWayline(workspaceId, waylineBody)
      .then(res => {
        disabled.value = false
        pushWaylineDetail(res.data.id)
      })
  }).catch((e: any) => {
    console.log('validate err', e)
  })
}

function pushWaylineDetail (waylineId: string) {
  // 缓存当前中心位置
  const curCenterPoint = useMapToolHook.getCenterPosition()
  store.commit('SET_CUR_CENTER_POINT', curCenterPoint)
  // 缓存机库信息
  const dockinfo = store.state.deviceState.dockInfo as any
  localStorage.setItem(ELocalStorageKey.DockInfo, JSON.stringify(dockinfo))

  if (waylineBody.template_types === '0') {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE + '?id=' + waylineId)
  } else if (waylineBody.template_types === '1') {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE2D + '?id=' + waylineId)
  } else if (waylineBody.template_types === '2') {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE3D + '?id=' + waylineId)
  } else if (waylineBody.template_types === '6') {
    root.$router.push('/' + ERouterName.EDIT_WAYLINECIRCLE + '?id=' + waylineId)
  }
}

function closeWayline () {
  root.$router.push('/' + ERouterName.WAYLINE)
}

function closePanel () {
  drawerVisible.value = false
  routeName.value = ''
}

function selectRoute () {
  drawerVisible.value = true
  routeName.value = 'WaylinePanel'
}

function selectDevice () {
  drawerVisible.value = true
  routeName.value = 'DockPanel'
}
</script>

<style lang="scss" scoped>
.create-plan-wrapper {
  background-color: #232323;
  color: fff;
  padding-bottom: 0;
  height: 90vh;
  display: flex;
  flex-direction: column;
  width: 285px;

  .header {
    height: 52px;
    border-bottom: 1px solid #4f4f4f;
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .content {
    height: calc(100% - 54px);
    overflow-y: auto;

    form {
      margin: 10px;
    }

    form label, input, .ant-input, .ant-calendar-range-picker-separator,
    .ant-input:hover, .ant-time-picker .anticon, .ant-calendar-picker .anticon {
      background-color: #232323;
      color: #fff;
    }

    .ant-input-suffix {
      color: #fff;
    }

    .plan-timer-form-item {

      .ant-radio-button-wrapper{
        background-color: #232323;
        color: #fff;
        width: 80%;
        text-align: center;
        &.ant-radio-button-wrapper-checked{
          background-color: #1890ff;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding:10px 0;

    button {
      width: 45%;
      color: #fff ;
      border: 0;
    }
  }
}

.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 70px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}
</style>
