<template>
  <div class="project-wayline-wrapper height-100" style="width: 100%;">
    <a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
      <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #4f4f4f; font-weight: 450;">
        <a-row>
          <a-col :span="1"></a-col>
          <a-col :span="20">照片文件夹</a-col>
        </a-row>
      </div>
      <div :style="{ height : height + 'px'}" class="scrollbar">
        <div id="data" class="height-100 uranus-scrollbar" v-if="true" @scroll="onScroll">
          <a-tree
            v-model:expandedKeys="expandedKeys"
            v-model:selectedKeys="selectedKeys"
            v-model:checkedKeys="checkedKeys"
            checkable
            @check="treeOnChecked"
            :blockNode="true"
            :showIcon="true"
            :tree-data="treeData"
          >
            <template #icon>
              <a-image :src="folderImg" :preview="false"></a-image>
            </template>
          </a-tree>
        </div>
        <div v-else>
          <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, watch, ref, computed } from 'vue'
import { message } from 'ant-design-vue'

import { deleteWaylineFile, downloadWaylineFile, analysisWaylineFile, getWaylineFiles, importKmzFile } from '/@/api/wayline'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { EllipsisOutlined, RocketOutlined, CameraFilled, UserOutlined, SelectOutlined, PlusOutlined, MinusOutlined } from '@ant-design/icons-vue'
import { DEVICE_NAME, EDeviceTypeName } from '/@/types/device'
import { useMyStore } from '/@/store'
import { WaylineFile, WaylinePoint } from '/@/types/wayline'
import { downloadFile } from '/@/utils/common'
import { IPage } from '/@/api/http/type'
import { CURRENT_CONFIG } from '/@/api/http/config'
import { load } from '@amap/amap-jsapi-loader'
import { getRoot } from '/@/root'
import { useRoute } from 'vue-router'
import X2JS from 'x2js'
import JSZip from 'jszip'
import type { TreeProps } from 'ant-design-vue'
import folderImg from '/@/assets/file.png'
import { compressDownload, batchDownloadMediaFiles, getMediaFiles, saveDataSet, createFolder, deleteFile, updateFileName, uploadZip } from '/@/api/media_v2'
import { preview } from 'vite'

const route = useRoute()
const waylineRoute = computed(() => {
  return true
})
const loading = ref(false)
const currentWayline = ref<WaylineFile>()
const pointList = ref<WaylinePoint []>()
const store = useMyStore()

const treeData: TreeProps['treeData'] = reactive([
  {
    title: '全部文件',
    key: 'all',
    children: [],
  },
])
const folderData = reactive({
  data: [] as any[],
  dataChild: [] as any[],
})

const expandedKeys = ref<string[]>(['all'])
const selectedKeys = ref<string[]>([])
const checkedKeys = ref<string[]>([])

watch(expandedKeys, () => {
  console.log('expandedKeys', expandedKeys)
})
watch(selectedKeys, () => {
  console.log('selectedKeys', selectedKeys)
})
watch(checkedKeys, () => {
  // console.log('checkedKeys', checkedKeys)
})

watch(() => store.state.folderTreeRemovedData, data => {
  checkedKeys.value = data
  // const index = checkedKeys.value.findIndex(item => item === data)
  // if (index === -1) {
  //   return
  // }
  // checkedKeys.value.splice(index, 1)
})

const pagination :IPage = {
  page: 1,
  total: -1,
  page_size: 200
}

const root = getRoot()
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const deleteTip = ref(false)
const deleteWaylineId = ref<string>('')
const canRefresh = ref(true)
const importVisible = ref<boolean>(root.$router.currentRoute.value.name === ERouterName.WAYLINE)
const height = ref()

onMounted(() => {
  getFolderFiles()
})

function treeOnChecked (checkedKeys: string[], e: any) {
  store.commit('SET_FOLDER_TREE_SELECTED_DATA', e.checkedNodes)
}

function getFolderFiles () {
  getMediaFiles(workspaceId, {
    page: pagination.page,
    page_size: pagination.page_size,
    total: pagination.total
  },
  { category: 'media', parent_id: '-1' }).then(res => {
    if (res.code !== 0) {
      return
    }
    folderData.data = res.data.list
    res.data.list.forEach((item: any) => {
      treeData[0].children?.push({
        title: item.file_name,
        key: item.file_id,
      })
    })
  }).finally(() => {
    canRefresh.value = true
  })
}

async function getChildFolderFiles (parent_id: string) {
  getMediaFiles(workspaceId, {
    page: 1,
    page_size: 1000,
    total: 1000
  },
  { category: 'media', parent_id: parent_id }).then(res => {
    if (res.code !== 0) {
      return
    }
    res.data.list.forEach(element => {
      folderData.dataChild.push(element)
    })
    console.log('folderData.dataChild', folderData.dataChild)
    // folderData.dataChild = [...res.data.list]
  }).finally(() => {
    canRefresh.value = true
  })
}
</script>

<style lang="scss" scoped>
:global(.ant-tree-list) {
  background: rgb(40, 40, 40);
  color: #fff;
}
:global(.ant-tree-node-selected) {
  background: #3c3c3c !important;
}
:global(.ant-tree-title) {
  margin-left: 5px;
}
.route-icon {
  color: #fff;
  font-size: 16px;
}
.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.active{
  border: 1px solid #2b85e4;
}
.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}
</style>
