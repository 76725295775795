import App from './App.vue'
import router from './router'
import { antComponents } from './antd'
import { CommonComponents } from './use-common-components'
import 'virtual:svg-icons-register'
import store, { storeKey } from './store'
import { createInstance } from '/@/root'
import { useDirectives } from './directives'
import VueCesium from 'vue-cesium'
import 'vue-cesium/dist/index.css'
import zhHans from 'vue-cesium/es/locale/lang/zh-hans'

import '/@/styles/index.scss'
const app = createInstance(App)

app.use(store, storeKey)
app.use(router)
app.use(CommonComponents)
app.use(antComponents)
app.use(useDirectives)
app.use(VueCesium, {
  cesiumPath: '/Cesium/Cesium.js',
  accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiI1NmMyZjgzMi0xNGJjLTRlNGEtYmM3Ni0wMDFhODM0ODQzNzciLCJpZCI6MjEwMzM1LCJpYXQiOjE3MTM3NzA4NDJ9.zxQNYYpA3XwcYMa1mL98rMs7ZRf8mSwknSJQ9OWIjLg',
  locale: zhHans
})
app.mount('#demo-app')
