<template>
  <div class="project-wayline-wrapper height-100" style="width: 100%;">
    <a-spin :spinning="loading" :delay="300" tip="downloading" size="large">
    <div style="height: 50px; line-height: 50px; border-bottom: 1px solid #4f4f4f; font-weight: 450;">
      <a-row>
        <a-col :span="1"></a-col>
        <a-col :span="20">航线库</a-col>
        <a-col :span="1" v-if="importVisible && waylineRoute" class="flex-row flex-justify-end flex-align-center">
          <a-upload
            name="file"
            :multiple="false"
            :before-upload="beforeUpload"
            :show-upload-list="false"
            :customRequest="uploadFile"
          >
            <a-button type="text" style="color: white;">
              <SelectOutlined />
            </a-button>
          </a-upload>
        </a-col>
        <a-col v-if="importVisible && waylineRoute" :span="1" class="flex-row flex-justify-end flex-align-center">
          <router-link :to="{ name: ERouterName.CREATE_WAYLINE}">
            <PlusOutlined class="route-icon"/>
          </router-link>
        </a-col>
        <a-col v-if="importVisible && !waylineRoute" :span="2" class="flex-row flex-justify-end flex-align-center">
          <router-link :to="{ name: ERouterName.WAYLINE}">
            <CloseOutlined class="route-icon"/>
          </router-link>
        </a-col>
      </a-row>
    </div>
    <div v-show="waylineRoute" :style="{ height : height + 'px'}" class="scrollbar">
      <div id="data" class="height-100 uranus-scrollbar" v-if="waylinesData.data.length !== 0" @scroll="onScroll">
        <div v-for="wayline in waylinesData.data" :key="wayline.id">
          <div :class="currentWayline == wayline? 'wayline-panel active' : 'wayline-panel'" style="padding-top: 5px;" @click="selectRoute(wayline)">
            <div class="title">
              <a-tooltip :title="wayline.name">
                <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ wayline.name }}</div>
              </a-tooltip>
              <div class="ml10"><UserOutlined /></div>
              <a-tooltip :title="wayline.user_name">
                <div class="ml5 pr10" style="width: 80px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ wayline.user_name }}</div>
              </a-tooltip>
              <div class="fz20">
                <a-dropdown>
                  <a style="color: white;">
                    <EllipsisOutlined />
                  </a>
                  <template #overlay>
                    <a-menu theme="dark" class="more">
                      <!-- <a-menu-item v-if="wayline.template_types[0] === 0" @click="editdWayline(wayline.template_types[0], wayline.id)"> -->
                      <a-menu-item v-if="wayline.template_types[0] === 0 || wayline.template_types[0] === 1 || wayline.template_types[0] === 2 ||
                        wayline.template_types[0] === 6" @click="editdWayline(wayline.template_types[0], wayline.id)">
                        <span>编辑</span>
                      </a-menu-item>
                      <a-menu-item v-if="wayline.object_key" @click="downloadWayline(wayline.id, wayline.name)">
                        <span>下载</span>
                      </a-menu-item>
                      <a-menu-item @click="showWaylineTip(wayline.id)">
                        <span>删除</span>
                      </a-menu-item>
                    </a-menu>
                  </template>
                </a-dropdown>
              </div>
            </div>
            <div class="ml10 mt5" style="color: hsla(0,0%,100%,0.65);">
              <span><RocketOutlined /></span>
              <!-- <span class="ml5">{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(wayline.drone_model_key)] }}</span> -->
              <span class="ml5">{{ EDeviceTypeName[wayline.drone_model_key] }}</span>
              <span class="ml10"><CameraFilled style="border-top: 1px solid; padding-top: -3px;" /></span>
              <span class="ml5" v-for="payload in wayline.payload_model_keys" :key="payload.id">
                <!-- {{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(payload)] }} -->
                {{ EDeviceTypeName[payload] }}
              </span>
            </div>
            <div class="mt5 ml10" style="color: hsla(0,0%,100%,0.35);">
              <span class="mr10">更新于 {{ new Date(wayline.update_time).toLocaleString() }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <a-empty :image-style="{ height: '60px', marginTop: '60px' }" />
      </div>
      <a-modal v-model:open="deleteTip" width="450px" ok-text="确定" cancel-text="取消" :closable="false" :maskClosable="false" centered :okButtonProps="{ danger: true }" @ok="deleteWayline">
          <p class="pt10 pl20" style="height: 50px;">航线文件删除后无法恢复,是否删除?</p>
          <template #title>
              <div class="flex-row flex-justify-center">
                  <span>删除</span>
              </div>
          </template>
      </a-modal>
    </div>
    </a-spin>
    <div v-if="!waylineRoute">
      <router-view/>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { reactive, onMounted, onUpdated, ref, computed } from 'vue'
import { message } from 'ant-design-vue'
import { deleteWaylineFile, downloadWaylineFile, analysisWaylineFile, getWaylineFiles, importKmzFile } from '/@/api/wayline'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { EllipsisOutlined, RocketOutlined, CameraFilled, UserOutlined, SelectOutlined, PlusOutlined, CloseOutlined } from '@ant-design/icons-vue'
import { DEVICE_NAME, EDeviceTypeName } from '/@/types/device'
import { useMyStore } from '/@/store'
import { WaylineFile, WaylinePoint } from '/@/types/wayline'
import { downloadFile } from '/@/utils/common'
import { IPage } from '/@/api/http/type'
import { CURRENT_CONFIG } from '/@/api/http/config'
import { load } from '@amap/amap-jsapi-loader'
import { getRoot } from '/@/root'
import { useRoute } from 'vue-router'
import X2JS from 'x2js'
import JSZip from 'jszip'
import EventBus from '/@/event-bus'
import { deviceTsaUpdate } from '/@/hooks/use-g-map-tsa'

const route = useRoute()
const waylineRoute = computed(() => {
  return route.name === ERouterName.WAYLINE || route.name === ERouterName.SELECT_PLAN
})
const loading = ref(false)
const currentWayline = ref<WaylineFile>()
const pointList = ref<WaylinePoint []>()
const store = useMyStore()
const pagination :IPage = {
  page: 1,
  total: -1,
  page_size: 10
}

const waylinesData = reactive({
  data: [] as WaylineFile[]
})

const root = getRoot()
const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!
const deleteTip = ref(false)
const deleteWaylineId = ref<string>('')
const canRefresh = ref(true)
const importVisible = ref<boolean>(root.$router.currentRoute.value.name === ERouterName.WAYLINE)
const height = ref()

onMounted(() => {
  const parent = document.getElementsByClassName('scrollbar').item(0)?.parentNode as HTMLDivElement
  height.value = document.body.clientHeight - parent.firstElementChild!.clientHeight - 57
  getWaylines()

  const key = setInterval(() => {
    const data = document.getElementById('data')?.lastElementChild as HTMLDivElement
    if (pagination.total === 0 || Math.ceil(pagination.total / pagination.page_size) <= pagination.page || height.value <= data?.clientHeight + data?.offsetTop) {
      clearInterval(key)
      return
    }
    pagination.page++
    getWaylines()
  }, 1000)

  EventBus.off('redrawDock')
  EventBus.on('redrawDock', (dockInfos) => {
    const deviceTsaUpdateHook = deviceTsaUpdate()
    deviceTsaUpdateHook.redrawDock(dockInfos)
  })
})

function getWaylines () {
  if (!canRefresh.value) {
    return
  }
  canRefresh.value = false
  getWaylineFiles(workspaceId, {
    page: pagination.page,
    page_size: pagination.page_size,
    order_by: 'update_time desc'
  }).then(res => {
    if (res.code !== 0) {
      return
    }
    waylinesData.data = [...waylinesData.data, ...res.data.list]
    pagination.total = res.data.pagination.total
    pagination.page = res.data.pagination.page
  }).finally(() => {
    canRefresh.value = true
  })
}

function showWaylineTip (waylineId: string) {
  deleteWaylineId.value = waylineId
  deleteTip.value = true
}

function deleteWayline () {
  deleteWaylineFile(workspaceId, deleteWaylineId.value).then(res => {
    if (res.code === 0) {
      message.success('删除成功')
    }
    deleteWaylineId.value = ''
    deleteTip.value = false
    pagination.total = 0
    pagination.page = 1
    waylinesData.data = []
    getWaylines()
  })
}

function editdWayline (type: number, waylineId: string) {
  // 缓存机库信息
  const dockinfo = store.state.deviceState.dockInfo as any
  localStorage.setItem(ELocalStorageKey.DockInfo, JSON.stringify(dockinfo))

  if (type === 0) {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE + '?id=' + waylineId)
  } else if (type === 1) {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE2D + '?id=' + waylineId)
  } else if (type === 2) {
    root.$router.push('/' + ERouterName.EDIT_WAYLINE2D + '?id=' + waylineId)
  } else if (type === 6) {
    root.$router.push('/' + ERouterName.EDIT_WAYLINECIRCLE + '?id=' + waylineId)
  }
}

function downloadWayline (waylineId: string, fileName: string) {
  loading.value = true
  // 获取kml 解析参数
  downloadWaylineFile(workspaceId, waylineId).then(res => {
    if (!res) {
      return
    }
    const data = new Blob([res], { type: 'application/zip' })
    downloadFile(data, fileName + '.kmz')
  }).finally(() => {
    loading.value = false
  })
}

function selectRoute (wayline: WaylineFile) {
  if (!wayline.object_key) {
    message.error('当前航线编辑后未保存,请保存后使用!')
    return
  }
  // analysisWaylineFile(workspaceId, wayline.id).then(res => {
  //   if (!res) {
  //     return
  //   }
  //   // console.log('analysisWaylineFile=====', res.data)
  //   wayline.edit = false
  //   wayline.kml = res.data.kml
  //   wayline.wpml = res.data.wpml
  //   currentWayline.value = wayline
  //   // console.log('currentWayline=====', wayline)
  //   store.commit('SET_SELECT_WAYLINE_INFO', wayline)
  // }).finally(() => {
  //   loading.value = false
  // })
  // pointList.value = JSON.parse(JSON.stringify(wayline.point))
  // console.log(pointList.value)

  downloadWaylineFile(workspaceId, wayline.id).then(res => {
    if (!res) {
      return
    }
    const file = new Blob([res], { type: 'application/zip' })
    unZipKmz(file, wayline)
  })
}

async function unZipKmz (zipFile: Blob, wayline: WaylineFile) {
  // 判断是否是 .zip 文件
  const isZipFile = zipFile.type === 'application/zip'
  if (!isZipFile) {
    return
  }
  const zip = new JSZip()
  // 解压Zip压缩包，参数默认是二进制
  const zipData = await zip.loadAsync(zipFile)
  await zipData.forEach(function (relativePath, file) {
    // fix: 过滤 mac osx 系统压缩的副作用
    if (!relativePath.startsWith('__MACOSX')) {
      // 判断是否是目录
      if (!file.dir) {
        zip.file(file.name).async('string').then(function (u8) {
          if (file.name === 'wpmz/template.kml') {
            const x2js = new X2JS()
            const jsData: any = x2js.xml2js(u8)
            const kmlData = jsData.kml.Document
            wayline.edit = false
            const waylineType = kmlData.Folder.templateType.__text
            // console.log('waylineType======', waylineType, kmlData)
            const kmlPoints = [] as WaylinePoint[]
            // if (kmlData.Folder.Placemark instanceof Array) {
            if (waylineType === 'waypoint') {
              kmlData.Folder.Placemark.forEach((item: any, index: number) => {
                const waypoint = {} as WaylinePoint
                waypoint.index = index
                waypoint.lng = item.Point.coordinates.split(',')[0]
                waypoint.lat = item.Point.coordinates.split(',')[1]
                waypoint.height = item.height.__text
                kmlPoints.push(waypoint)
              })
            } else if (waylineType === 'mapping2d' || waylineType === 'mapping3d') {
              const height = kmlData.Folder.Placemark.height.__text
              const polygons = kmlData.Folder.Placemark.Polygon.outerBoundaryIs.LinearRing.coordinates.split('\n')
              polygons.forEach((item: any, index: number) => {
                const waypoint = {} as WaylinePoint
                waypoint.index = index
                waypoint.lng = item.split(',')[0]
                waypoint.lat = item.split(',')[1]
                waypoint.height = height
                kmlPoints.push(waypoint)
              })
            } else if (waylineType === 'mappingStrip') {
              const height = kmlData.Folder.Placemark.height.__text
              const polygons = kmlData.Folder.Placemark.LineString.coordinates.split('\n')
              polygons.forEach((item: any, index: number) => {
                const waypoint = {} as WaylinePoint
                waypoint.index = index
                waypoint.lng = item.split(',')[0].trim()
                waypoint.lat = item.split(',')[1].trim()
                waypoint.height = height
                kmlPoints.push(waypoint)
              })
              // console.log('kmlData.Folder.Placemark222', kmlData.Folder.Placemark, polygons, kmlPoints)
            }
            wayline.kml = kmlPoints
          }
          if (file.name === 'wpmz/waylines.wpml') {
            const x2js = new X2JS()
            const jsData: any = x2js.xml2js(u8)
            const wpmlData = jsData.kml.Document
            wayline.edit = false
            const wpmlPoints = [] as WaylinePoint[]
            if (wpmlData.Folder instanceof Array) {
              wpmlData.Folder.forEach((item: any, index: number) => {
                item.Placemark.forEach((item2: any, index2: number) => {
                  const waypoint = {} as WaylinePoint
                  waypoint.index = index2
                  waypoint.lng = item2.Point.coordinates.split(',')[0]
                  waypoint.lat = item2.Point.coordinates.split(',')[1]
                  waypoint.height = item2.executeHeight.__text
                  wpmlPoints.push(waypoint)
                })
              })
            } else {
              wpmlData.Folder.Placemark.forEach((item: any, index: number) => {
                const waypoint = {} as WaylinePoint
                waypoint.index = index
                waypoint.lng = item.Point.coordinates.split(',')[0]
                waypoint.lat = item.Point.coordinates.split(',')[1]
                waypoint.height = item.executeHeight.__text
                wpmlPoints.push(waypoint)
              })
            }
            wayline.wpml = wpmlPoints
            // console.log('wayline========', wayline)
            localStorage.setItem(wayline.id, JSON.stringify(wayline))
            store.commit('SET_SELECT_WAYLINE_INFO', wayline)
          }
        })
      }
    }
  })
}

function onScroll (e: any) {
  const element = e.srcElement
  if (element.scrollTop + element.clientHeight >= element.scrollHeight - 5 && Math.ceil(pagination.total / pagination.page_size) > pagination.page && canRefresh.value) {
    pagination.page++
    getWaylines()
  }
}

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
const fileList = ref<FileItem[]>([])

function beforeUpload (file: FileItem) {
  fileList.value = [file]
  loading.value = true
  return true
}
const uploadFile = async () => {
  fileList.value.forEach(async (file: FileItem) => {
    const fileData = new FormData()
    fileData.append('file', file, file.name)
    // console.log('111111111', fileList.value, file)
    await importKmzFile(workspaceId, fileData).then((res) => {
      if (res.code === 0) {
        // message.success(`${file.name} file uploaded successfully`)
        message.success(`${file.name} 文件上传成功`)
        canRefresh.value = true
        pagination.total = 0
        pagination.page = 1
        waylinesData.data = []
        getWaylines()
      }
    }).finally(() => {
      loading.value = false
      fileList.value = []
    })
  })
}
</script>

<style lang="scss" scoped>
.route-icon {
  color: #fff;
  font-size: 16px;
}
.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.active{
  border: 1px solid #2b85e4;
}
.uranus-scrollbar {
  overflow: auto;
  scrollbar-width: thin;
  scrollbar-color: #c5c8cc transparent;
}
</style>
