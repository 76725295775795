<template>
  <div class="demo-app">
    <a-config-provider :locale="zhCN" :message="message">
      <router-view />
    </a-config-provider>
    <!-- <div class="map-wrapper">
      <GMap/>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { onMounted } from 'vue'
import { CURRENT_CONFIG as config } from '/@/api/http/config'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

message.config({
  maxCount: 3,
})

onMounted(() => {
  document.title = config.appName ? config.appName : '低空数智巡检云平台'
})
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%
}
.amap-logo {
  display: none !important;
}
.amap-copyright {
  display: none !important;
}
</style>
