import X2JS from 'x2js'
import JSZip from 'jszip'
import FileSaver from 'file-saver'
import { updateKmzFile } from '/@/api/wayline'

/**
 * 下载文件
 * @param data
 * @param fileName
 */
export function downloadFile (data: Blob, fileName: string) {
  const lable = document.createElement('a')
  lable.href = window.URL.createObjectURL(data)
  lable.download = fileName
  lable.click()
  URL.revokeObjectURL(lable.href)
}

// export function downloadRecordFile (url: string, fileName: string) {
//   const lable = document.createElement('a')
//   lable.href = url
//   lable.download = fileName
//   lable.click()
//   URL.revokeObjectURL(lable.href)
// }

// 仅支持视频下载和图片下载
/**
 *
 * @param {文件路径} url
 * @param {文件名称} name
 */
export function downloadRecordFile (url: string, name: string) {
  const xhr = new XMLHttpRequest()
  xhr.open('GET', url, true)
  xhr.responseType = 'arraybuffer' // 返回类型blob
  xhr.onload = function () {
    if (xhr.readyState === 4 && xhr.status === 200) {
      const blob = this.response
      console.log(blob)
      // 转换一个blob链接
      // 注: URL.createObjectURL() 静态方法会创建一个 DOMString(DOMString 是一个UTF-16字符串)，
      // 其中包含一个表示参数中给出的对象的URL。这个URL的生命周期和创建它的窗口中的document绑定
      const downLoadUrl = window.URL.createObjectURL(new Blob([blob], {
        type: 'video/mp4'
      }))
      // 视频的type是video/mp4，图片是image/jpeg
      // 01.创建a标签
      const a = document.createElement('a')
      // 02.给a标签的属性download设定名称
      a.download = name
      // 03.设置下载的文件名
      a.href = downLoadUrl
      // 04.对a标签做一个隐藏处理
      a.style.display = 'none'
      // 05.向文档中添加a标签
      document.body.appendChild(a)
      // 06.启动点击事件
      a.click()
      // 07.下载完毕删除此标签
      a.remove()
    };
  }
  xhr.send()
}

export function jsonToXML (name: string, workspaceId: string, waylineId: string, obj: any) {
  const x2js = new X2JS()
  const xmlData = x2js.js2xml(obj).replace('<xml>', '<?xml version="1.0" encoding="UTF-8"?>')
    .replace('<kml>', '<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:wpml="http://www.dji.com/wpmz/1.0.3">')
    .replace('</xml>', '')
  // const domData = x2js.xml2dom(xmlData)
  // const root = domData.documentElement

  // const version = root.ownerDocument.createAttribute('version')
  // version.value = '1.0'
  // const encoding = root.ownerDocument.createAttribute('encoding')
  // encoding.value = 'UTF-8'
  // root.setAttributeNode(version)
  // root.setAttributeNode(encoding)

  // root.lookupPrefix('?')

  // const xmlns = root.ownerDocument.createAttribute('xmlns')
  // xmlns.value = 'http://www.opengis.net/kml/2.2'
  // const xmlns2 = root.ownerDocument.createAttribute('xmlns:wpml')
  // xmlns2.value = 'http://www.dji.com/wpmz/1.0.3'
  // const kml = root.getElementsByTagName('kml')[0]
  // kml.setAttributeNode(xmlns)
  // kml.setAttributeNode(xmlns2)
  // const newXmlData = x2js.js2xml(x2js.dom2js(domData)).replace('<xml version="1.0" encoding="UTF-8">', '<?xml version="1.0" encoding="UTF-8"?>').replace('</xml>', '')

  // console.log('newXmlData data is: ', xmlData)

  handleBatchDownload(name, workspaceId, waylineId, xmlData)

  // const url = window.URL.createObjectURL(new Blob([xmlData], { type: 'text/xml' }))
  // const link = document.createElement('a')
  // link.style.display = 'none'
  // link.href = url
  // link.setAttribute('download', 'template.kml')
  // document.body.appendChild(link)
  // link.click()

  // const url2 = window.URL.createObjectURL(new Blob([xmlData], { type: 'text/xml' }))
  // const link2 = document.createElement('a')
  // link2.style.display = 'none'
  // link2.href = url2
  // link2.setAttribute('download', 'waylines.wpml')
  // document.body.appendChild(link2)
  // link2.click()
}

// 批量下载
export async function handleBatchDownload (name: string, workspaceId: string, waylineId: string, xmlData: string) {
  const zip = new JSZip()
  const wpmz = zip.folder('wpmz')
  wpmz?.file('template.kml', xmlData, { binary: true })
  wpmz?.file('waylines.wpml', xmlData, { binary: true })

  zip.generateAsync({ type: 'blob' }).then(content => { // 生成二进制流
    FileSaver.saveAs(content, name + '.kmz') // 利用file-saver保存文件
    const file = new File([content], name + '.kmz')
    const fileData = new FormData()
    fileData.append('file', file)
    updateKmzFile(workspaceId, fileData, waylineId).then((res: { code: number }) => {
      if (res.code === 0) {
        console.log(res)
      }
    }).finally(() => {
    })
  }).catch(err => {
    throw err
  })
}
