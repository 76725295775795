import { GeojsonCoordinate } from '../utils/genjson'
import { getRoot } from '/@/root'

export function useMapTool () {
  const root = getRoot()
  const map = root.$map
  const AMap = root.$aMap

  function panTo (coordinate: GeojsonCoordinate) {
    map.panTo(coordinate, 100)
    map.setZoom(18, false, 100)
  }

  // 获取Camera中心点坐标
  function getCenterPosition () {
    const result = root.$viewer.camera.pickEllipsoid(new root.$Cesium.Cartesian2(root.$viewer.canvas.clientWidth / 2, root.$viewer.canvas.clientHeight / 2))
    const curPos = root.$Cesium.Ellipsoid.WGS84.cartesianToCartographic(result)
    const lon = curPos.longitude * 180 / Math.PI
    const lat = curPos.latitude * 180 / Math.PI
    const height = getHeight()
    return {
      longitude: lon,
      latitude: lat,
      height: height
    }
  }

  // 获取Camera高度
  function getHeight () {
    return root.$viewer ? root.$viewer.scene.globe.ellipsoid.cartesianToCartographic(root.$viewer.camera.position).height : 0
  }

  function flyToPoint (point: any) {
    root.$viewer.camera.flyTo({
      destination: root.$Cesium.Cartesian3.fromDegrees(point.longitude, point.latitude, point.height),
      duration: 1,
      orientation: {
        heading: root.$Cesium.Math.toRadians(0.0),
        pitch: root.$Cesium.Math.toRadians(-90.0),

      }
    })
  }

  return {
    panTo,
    getHeight,
    getCenterPosition,
    flyToPoint,
  }
}
